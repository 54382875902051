import React from 'react'
import { CButton, CCol, CFormInput, CFormLabel, CFormSelect, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import { AssignRoleModalState, ModalState } from './modalData';
import { useTranslation } from 'react-i18next';

interface propTypes {
    modals: ModalState
    state: AssignRoleModalState
    setModals: any
}

const AssignRoleModal = (props: propTypes) => {

    const { state, modals, setModals } = props;
    const { t } = useTranslation()

    const handleAssignRoleClose = () => {
        const assignModalRole: AssignRoleModalState = {
            ...state,
            visible: false
        };
        setModals((prev: ModalState) => ({ ...prev, assignModalRole }));
    }


    return (
        <>
            <CModal alignment="center" onClose={handleAssignRoleClose} visible={state.visible}>
                <CModalHeader className='border-0'>
                    <CModalTitle>
                        <div className='fs-5'>Assign to Role</div>
                        <span className='modal-header-description text-muted'>{t("employee.modal.select_role_header_description")}
                            <span className='text-dark'> {modals.employeeSelected?.length || 0} {t("employee.employees")}</span>
                        </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CCol>
                        <CFormLabel className='filter-label' htmlFor='modal-select-role'>{t("employee.modal.select_role")} *</CFormLabel>
                        <CFormSelect className='filter-form-select' type='text' id='modal-select-role' aria-describedby='filter role' >
                            <option value="" disabled={false} selected={true}>{t("employee.modal.role")}</option>
                        </CFormSelect>
                    </CCol>
                    <CCol className='mt-4'>
                        <CFormLabel className='filter-label' htmlFor='modal-effective-date'>{t("employee.modal.effective_date")}</CFormLabel>
                        <CFormInput className='filter-form-select' type='date' id='modal-effective-date' aria-describedby='filter role' >
                        </CFormInput>
                    </CCol>
                </CModalBody>
                <CModalFooter>
                    <CRow className='w-100'>
                        <CCol>
                            <CButton variant='outline' className='w-100' color="secondary" onClick={handleAssignRoleClose}>Close</CButton>
                        </CCol>
                        <CCol>
                            <CButton className='w-100' color="primary">Save</CButton>
                        </CCol>
                    </CRow>
                </CModalFooter>
            </CModal>
        </>
    )
}

export default AssignRoleModal