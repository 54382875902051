import React from 'react'
import {
  CButton,
  CFormCheck,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle
} from "@coreui/react";
import { IModalAddLocation } from '../types/IModalAddLocation';
import CommonService from 'services/CommonService';
import Business from 'services/Business/Business';
import { useTranslation } from 'react-i18next';

const ModalAddLocation = (props: IModalAddLocation) => {
  const { t } = useTranslation();
  const { show, city_id, is_default, district_id, is_warehouse, setModalAdd, ward_id, vendor_location_name, address } = props;
  const [cities, setCities] = React.useState<any>();
  const [errors, setErrors] = React.useState<any>({});

  const [districts, setDistricts] = React.useState<any>([]);
  const [wards, setWards] = React.useState<any>([]);
  const user = React.useMemo(() => CommonService.user(), []);
  const handleClose = () => {
    setModalAdd({ show: false, setModalAdd });
    setErrors({});
  }

  const handleChange = (name: keyof IModalAddLocation, value: any) => {
    setModalAdd(prev => ({ ...prev, [name]: value }));
  }

  const handleSave = () => {
    console.log(user.user_id);
    
    const data = {
      city_id, district_id, ward_id, vendor_location_name, address,
      is_warehouse: is_warehouse ? 1 : 0,
      is_default: is_default ? 1 : 0,
      user_id: user.user_id,
      vendor_id: user.vendor_id
    }

    Business.createLocation(data).then(res => {
      if (!res.data.code) {
        handleClose();
      }
      else {
        setErrors(res.data.messageObject)
      }
    }, (err) => console.log(err))
  }

  React.useEffect(() => {
    CommonService.provinces().then((res) => {
      setCities(res.data.data)
    }, (err) => console.log(err))
  }, [])

  React.useEffect(() => {
    CommonService.districts(+city_id).then(res => {
      if (city_id) {
        setDistricts(res.data.data)
      }
      else {
        setDistricts([]);
        setWards([]);
        handleChange('district_id', '')
        handleChange('ward_id', '')
      }
    })
  }, [city_id])

  React.useEffect(() => {
    CommonService.wards(+district_id).then(res => {
      if (district_id) {
        setWards(res.data.data);
      }
      else {
        setWards([]);
        handleChange('ward_id', '');
      }
    })
  }, [district_id])

  return (
    <>
      <CModal visible={show} unmountOnClose={true}>
        <CModalHeader closeButton={false}>
          <CModalTitle>{t('registration.add_new_location')}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div className="container">
            <div className="mb-2">Location 1</div>
            <div className="w-100 mb-2">
              <select className="form-select text-secondary" value={city_id || ''} onChange={(e) => handleChange('city_id', e.target.value)}>
                <option value=''>{t('common.city')}</option>
                {
                  cities && cities.map((city) => <option key={city.id} value={city.id}>{city.name}</option>)
                }
              </select>
              {errors?.city_id && <span className='text-danger'>{errors?.city_id}</span>}
            </div>
            <div className="mb-2">
              <select className="form-select text-secondary" value={district_id || ''} onChange={(e) => handleChange('district_id', e.target.value)}>
                <option value=''>{t('common.district')}</option>
                {
                  districts.map((district) => <option key={district.id} value={district.id}>{district.name}</option>)
                }
              </select>
              {errors?.district_id && <span className='text-danger'>{errors?.district_id}</span>}
            </div>
            <div className="mb-2">
              <select className="form-select text-secondary" value={ward_id || ''} onChange={(e) => handleChange('ward_id', e.target.value)}>
                <option value=''>{t('common.ward')}</option>
                {
                  wards.map((ward) => <option key={ward.id} value={ward.id}>{ward.name}</option>)
                }
              </select>
              {errors?.ward_id && <span className='text-danger'>{errors?.ward_id}</span>}
            </div>
            <div className="mb-2">
              <input
                type="text"
                value={vendor_location_name || ''}
                onChange={(e) => handleChange('vendor_location_name', e.target.value)}
                className="form-control "
                placeholder={t('registration.location_name')}
              />
              {errors?.vendor_location_name && <span className='text-danger'>{errors?.vendor_location_name}</span>}
            </div>
            <div className="mb-2">
              <input
                type="text"
                value={address || ''}
                onChange={(e) => handleChange('address', e.target.value)}
                className="form-control"
                placeholder="Address line"
              />
              {errors?.address && <span className='text-danger'>{errors?.address}</span>}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mb2">
                  <CFormCheck
                    id="default-checkbox-add"
                    label={t('registration.set_default_location')}
                    onChange={(e) => handleChange('is_default', e.target.checked)}
                    checked={is_default}
                  />
                  {errors?.is_default && <span className='text-danger'>{errors?.is_default}</span>}
                </div>
                <div className="mb-2">
                  <CFormCheck
                    id="warehouse-checkbox-add"
                    onChange={(e) => handleChange('is_warehouse', e.target.checked)}
                    label={<>{t('registration.set_as_warehouse')} <span className='text-danger'>*</span></>}
                    checked={is_warehouse}
                  />
                  {errors?.is_warehouse && <span className='text-danger'>{errors?.is_warehouse}</span>}
                </div>
              </div>
            </div>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleClose}>
            Close
          </CButton>
          <CButton color="primary" onClick={handleSave}>Save changes</CButton>
        </CModalFooter>
      </CModal>

    </>
  )
}

export default ModalAddLocation