import React, { useState } from "react";
import {
    CRow,
    CCol,
    CContainer,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
} from "@coreui/react";
import { useTranslation } from "react-i18next";

import Pagination from "components/Pagination/Pagination";
import NoDataTable from "components/NoDataTable";
import TableRow from "./TableRow";
import SearchBar from "./searchBarCustomer";
import ModalUpdateCustomer from "./Modal/ModalUpdateCustomer";
import ModalUpdateCompelete from "./Modal/ModalUpdateCompelete";
import { getListCustomer, selectListCustomer, selectDataUploadFile } from "features/business/businessSlices";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { PerPage } from "utils/constant";
import Business from "services/Business/Business";
import Customer from "./types/Customer";


const employeeCol = {
    id: {
        label: "customer.table.id",
        isShow: true,
    },
    name: {
        label: "customer.table.name",
        isShow: true,
    },
    type: {
        label: "customer.table.type",
        isShow: true,
    },
    business_name: {
        label: "customer.table.business_name",
        isShow: true,
    },
    status: {
        label: "customer.table.status",
        isShow: true,
    },

};

const CustomerScreen = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [customers, setCustomers] = React.useState<Customer[]>();
    const [col] = useState(employeeCol);
    const [searchParams] = useSearchParams();
    const [showModal, setShowModal] = React.useState(false);
    const [showModalCompelete, setShowModalCompele] = React.useState(false);
    const listCustomer = useAppSelector(selectListCustomer);
    const vendor_id = localStorage.getItem('vendor_id');
    const { t } = useTranslation();
    const dataUploadFile = useAppSelector(selectDataUploadFile);
    const [searchData, setSearchData] = React.useState({ keyword: '' });

    React.useEffect(() => {
        if (dataUploadFile?.code === 0) {
            if (showModal === true) {
                setShowModal(false);
                setShowModalCompele(true);
            }
        }
    }, [dataUploadFile]);


    const tranferPageto = (page: string, limit: string) => {
        navigate({
            pathname: location.pathname,
            search: `?keyword=${searchParams.get("keyword") || ""}&page=${page}&limit=${limit}`
        });
    };

    const handelShowPopup = () => {
        setShowModal(!showModal);
    };

    React.useEffect(() => {
        const data = {
            vendor_id: vendor_id,
            page: 1,
            keyword: searchData.keyword,
        };
        Business.customerList(data.vendor_id, data.page, data.keyword).then(res => {
            if (res.data.code === 0) {
                setCustomers((prev)=> {
                    const customers = res.data.data.data.map(c => new Customer(c));
                    return customers
                })
            }
        });
    }, [searchData]);

    return (
        <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
            <CRow className="mb-4">
                <CCol className="mb-4">
                    <h2>{t('customer.customer')}</h2>
                </CCol>
                <CCol className="d-flex">
                    <div className="ms-auto">
                        <CButton onClick={handelShowPopup} className="ms-2" variant="outline" color="secondary">
                            {t('customer.upload_customer')}
                        </CButton>
                        <CButton onClick={() => navigate("add-customer")} className="ms-2" color="primary">
                            {t('common.add_new')}
                        </CButton>
                    </div>
                </CCol>
            </CRow>
            <div style={{
                background: "#F3F5F6",
                borderRadius: '8px',
                padding: 20,
                height: 110,
                marginBottom: 24
            }}>
                <SearchBar searchData={searchData} setSearchData={setSearchData} />
            </div>
            <div>
                <div className="table-wrapper">
                    <CTable responsive="xl" align="middle">
                        <CTableHead>
                            <CTableRow className="py-4">
                                <CTableHeaderCell scope="col"></CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.id.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.name.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.type.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.business_name.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.status.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {customers && customers?.map((customer: Customer) => (
                                <TableRow key={customer.data.id} customer={customer}  />
                            ))}
                        </CTableBody>
                    </CTable>
                    {!listCustomer?.data?.length && <NoDataTable />}
                    {!!listCustomer?.data?.length && <Pagination
                        limit={searchParams.get("limit") || PerPage.perPage10}
                        totalItems={listCustomer?.total}
                        current_page={listCustomer?.current_page}
                        navigate={tranferPageto}
                    />}
                </div>
            </div>

            <ModalUpdateCustomer onCancel={() => setShowModal(false)} visible={showModal} />
            <ModalUpdateCompelete onCancel={() => setShowModalCompele(false)} data={dataUploadFile?.data} visible={showModalCompelete} />

        </CContainer>
    );
};

export default CustomerScreen;