import moment from "moment";

export interface IPayment {
  id?: any;
  assigned_user_id?: any;
  bank_out_credit_id?: any;
  bank_out_ubank_id?: any;
  brand_principal_id?: any;
  customer_id?: any;
  description?: any;
  cash_out_id?: any;
  credit_line_detail?: any;
  created_at?: any;
  order_numbers?: any;
  payment_date?: any;
  payment_type?: any;
  receive_amount?: any;
  request_amount?: any;
  status?: any;
  ubank_detail?: UbankDetail;
  updated_at?: any;
  vendor_id?: any;
  assigned_user: AssignedUser;
  cash_out_detail: CashOutDetail;
  customer: Customer;
  order_list: Order[];
}

export interface AssignedUser {
  id?: any;
  last_name?: any;
  first_name?: any;
  middle_name?: any;
}

export interface CashOutDetail {
  id?: any;
  payment_amount?: any;
  cash_out_id?: any;
  created_at?: any;
  credit_line_detail?: any;
}

export interface Customer {
  first_name?: any;
  id?: any;
  last_name?: any;
}

export interface Order {
  id?: any;
  order_number?: any;
  total?: any;
}

export interface UbankDetail {
  id?: any
  payment_amount?: any
  status?: 2
}

export default class PaymentObj {
  payment: IPayment;
  dateFormat = 'YYYY-MM-DD'
  constructor(data: any) {
    this.payment = { ...data };
  }
  getCustomerName() {
    return `${this.payment.customer.first_name} ${this.payment.customer.last_name}`
  }

  getAssignedEmployeeName() {
    return `${this.payment.assigned_user.first_name} ${this.payment.assigned_user.middle_name || ''} ${this.payment.assigned_user.last_name}`
  }
  getPaymenRequest() {
    return `${moment(this.payment.created_at).format(this.dateFormat)}`
  }
  getPaymentReceipt() {
    switch (this.payment.status) {
      case 1:
        return 'Unpaid'
      case 2:
        return 'Pending'
      case 3:
        return this.payment.payment_date
      default:
        return 'Unpaid'
    }
  }
  getAmountRequest() {
    return `${this.payment.request_amount}`;
  }

  getAmountReceived() {
    return this.payment.receive_amount ? `${this.payment.receive_amount} VND` : '';
  }
  getCreditLine() {
    return `${this.payment.credit_line_detail || ''}`
  }
  getBankPayment() {
    return `${this.payment.ubank_detail?.payment_amount ? `${this.payment.ubank_detail?.payment_amount} VND` : ''}`
  }

  getCashPayment(){
    return this.payment.cash_out_detail?.payment_amount ? `${this.payment.cash_out_detail?.payment_amount} VND` : ''
  }
}
