import React, { useState } from "react";
import {
  CRow,
  CCol,
  CContainer,
  CButton,
  CTable,
  CTableHead,
  CTableBody,
  CTabPane,
  CTabContent
} from "@coreui/react";

import { useTranslation } from "react-i18next";
import Pagination from "components/Pagination/Pagination";
import NoDataTable from "components/NoDataTable";
import StatusBar from "./components/StatusBar";
import TableRow from "./components/TableRow";
import {
  AssignManagerModalState,
  AssignRoleModalState,
  ModalState,
} from "./modals/modalData";
import AssignManagerModal from "./modals/AssignManagerModal";
import FilterBar from "./components/FilterBar";
import ModalUploadEmployees from "./modals/ModalUploadEmployees";
import AssignRoleModal from "./modals/AssignRoleModal";
import ModalUploadCompelete from "./modals/ModalUploadCompelete";
import { selectDataDownloadFileEmployee } from "features/business/businessSlices";
import EmployeesService from "services/Employee/EmployeesService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/hooks";
import { STATUS } from "./constant";
import Employee from "./interfaces/EmployeeDto";
import TableHeader from "./components/TableHeader";
import TableApproveHeader from "./components/TableApproveHeader";
import AppoveTableRow from "./components/AppoveTableRow";
import ReignedTableRow from "./components/ReignedTableRow";
import ResignedTableHeader from "./components/ResignedTableHeader";
import ApproveRequestModal from "./modals/ApproveRequestModal";
import CommonService from "services/CommonService";
import { Tab } from "react-bootstrap";

const modalsInit: ModalState = {
  assignModalManager: {
    managers: [],
    visible: false,
  },
  assignModalRole: {
    visible: false,
  },
  employeeSelected: null,
};

const TableHeaderRender = ({ listType }: any) => {
  switch (listType) {
    case STATUS.ACTIVE:
      return <TableHeader />;
    case STATUS.RESIGN:
      return <ResignedTableHeader />;
    default:
      return <TableApproveHeader />;
  }
};

interface SearchData {
  keyword?: string;
  location_id?: string;
  listType: STATUS;
  manager_by?: string
}

const EmployeeScreen = () => {
  const [employeeList, setEmployeeList] = useState<any>();
  const dataUploadFile = useAppSelector(selectDataDownloadFileEmployee);
  const [modals, setModals] = useState<ModalState>(modalsInit);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalUploadCompelete, setModalUploadCompelete] = useState(false);
  const [checkedIDs, setCheckedIDs] = useState<Array<string>>([]);
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { user_id } = CommonService.user();

  const [selectingEmployee, setSelectingEmployee] = React.useState<any>();
  const [modalApprove, setModalApprove] = React.useState(false)


  const [searchData, setSearchData] = useState<SearchData>({
    keyword: "",
    location_id: "",
    listType: state?.beforeTab || STATUS.ACTIVE,
    manager_by: ""
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [requestCount, setRequestsCount] = React.useState<number>(0);

  const confirmApprove = () => {
    const { id } = selectingEmployee;
    const data = {
      employee_id: id,
      user_id: user_id,
      vendor_id: localStorage.getItem('vendor_id')
    }
    EmployeesService.approve(data).then(res => {
      if (res.data.code === 0) {
        getEmployees();
      }
    }).finally(() => setModalApprove(false));
  }
  const cancelApprove = () => {
    setSelectingEmployee({});
    setModalApprove(false);
  }
  const onModalApprove = (emp) =>{
    console.log(emp);
    setModalApprove(true);
    setSelectingEmployee(emp);
  }

  const handleCheckbox = (id: string) => {
    const isChecked = checkedIDs.includes(id);
    isChecked
      ? setCheckedIDs((prev) => prev.filter((previd) => previd !== id))
      : setCheckedIDs((prev) => [...prev, id]);
  };

  const handleAssignManager = () => {
    const assignModalManager: AssignManagerModalState = {
      ...modals.assignModalManager,
      visible: true,
    };
    setModals((prev) => ({ ...prev, assignModalManager: assignModalManager }));
  };

  const handleAssignRole = () => {
    const assignModalRole: AssignRoleModalState = {
      ...modals.assignModalRole,
      visible: true,
    };
    setModals((prev) => ({ ...prev, assignModalRole: assignModalRole }));
  };

  const tranferPageto = (page: string, limit: string) => {
    navigate({
      pathname: location.pathname,
      search: `?keyword=${searchParams.get("keyword") || ""
        }&page=${page}&limit=${limit}`,
    });
  };

  const getEmployees = async () => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      const response = await EmployeesService.get(
        searchData.listType,
        searchData.keyword || "",
        searchParams.get("page") || "",
        searchParams.get("limit") || "",
        searchData.location_id || "",
        searchData.manager_by || "",
      );
      setEmployeeList(response.data.data);
    }
  };

  const getCountRequest = async () => {
    const user_id = localStorage.getItem("user_id");
    if (user_id) {
      const response = await EmployeesService.get(
        'list-approve-request',
        searchData.keyword || "",
        searchParams.get("page") || "",
        "99999",
        searchData.location_id || "",
        searchData.manager_by || "",
      );
      console.log(response.data?.data?.total_items);
      
      setRequestsCount(response.data?.data?.total_items || 0);
    }
  }

  const downloadSample = async () => {
    try {
      const response = await EmployeesService.dowloadSample("1");
      const link = document.createElement("a");
      link.href = response.data.data.file_path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    setModals((prev) => ({ ...prev, employeeSelected: checkedIDs }));
  }, [checkedIDs]);

  React.useEffect(() => {
    getEmployees();
    getCountRequest();
  }, [searchData, searchParams]);

  React.useEffect(() => {
    if (dataUploadFile?.code === 0) {
      if (modalUpload === true) {
        setModalUpload(false);
        setModalUploadCompelete(true);
      }
    }
  }, [dataUploadFile]);

  return (
    <>
      <AssignManagerModal
        state={modals.assignModalManager}
        modals={modals}
        setModals={setModals}
      />
      <AssignRoleModal
        state={modals.assignModalRole}
        modals={modals}
        setModals={setModals}
      />

      <ModalUploadEmployees
        onCancel={() => setModalUpload(false)}
        visible={modalUpload}
        downloadFile={downloadSample}
      />
      <ModalUploadCompelete
        onCancel={() => setModalUploadCompelete(false)}
        data={dataUploadFile?.data}
        visible={modalUploadCompelete}
      />
      <ApproveRequestModal show={modalApprove} onCancel={cancelApprove} onConfirm={confirmApprove} />


      <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
        <CRow className="mb-4">
          <CCol sm={12} md={12} xl={4}>
            <h2>{t('employee.employees')}</h2>
          </CCol>
          <CCol className="d-flex" sm={12} md={12} xl={8}>
            <div className="ms-auto">
              <CButton className="ms-2" variant="outline" color="secondary">
                <a
                  onClick={downloadSample}
                  download
                  style={{ textDecoration: "none" }}
                >
                  {t("employee.action.download_sample_files")}{" "}
                </a>
              </CButton>
              <CButton
                onClick={() => setModalUpload(true)}
                className="ms-2"
                variant="outline"
                color="secondary"
              >
                {t("employee.action.upload_employee_list")}
              </CButton>
              <Link
                className="btn btn-primary ms-2"
                to="/employee_management/employees/add-new"
              >
                {t("employee.action.add_new")}
              </Link>
            </div>
          </CCol>
        </CRow>

        <StatusBar searchData={searchData} setSearchData={setSearchData} requestCount={requestCount} />

        {!checkedIDs.length && (
          <div className="filter-bar-wraper py-4 my-4 px-3">
            <FilterBar searchData={searchData} setSearchData={setSearchData} />
          </div>
        )}

        {!!checkedIDs.length && (
          <div className="filter-bar-wraper py-4 my-4 px-3">
            <CButton
              className="ms-2"
              variant="outline"
              color="secondary"
              onClick={handleAssignRole}
            >
              {t("employee.action.assign_role")}
            </CButton>
            <CButton
              className="ms-2"
              variant="outline"
              color="secondary"
              onClick={handleAssignManager}
            >
              {t("employee.action.assign_manager")}
            </CButton>
            <CButton
              className="ms-2"
              color="transparent"
              onClick={() => setCheckedIDs([])}
            >
              {t("buttonText.cancel")}
            </CButton>
          </div>
        )}

        <div className="table-wrapper">
          <Tab.Container activeKey={searchData.listType} unmountOnExit={true}>
            <Tab.Content>
              <Tab.Pane eventKey={STATUS.ACTIVE}>
                <CTable responsive="xl" align="middle">
                  <CTableHead>
                    <TableHeaderRender listType={searchData.listType} />
                  </CTableHead>
                  <CTableBody>
                    {
                      employeeList?.data.map((emp, idx) => {
                        return <TableRow key={idx} employee={new Employee({ ...emp })} handleCheckbox={handleCheckbox} checkedIDs={checkedIDs} />
                      })
                    }
                  </CTableBody>
                </CTable>
              </Tab.Pane>
              <Tab.Pane eventKey={STATUS.APPROVE}>
                <CTable responsive="xl" align="middle">
                  <CTableHead>
                    <TableHeaderRender listType={searchData.listType} />
                  </CTableHead>
                  <CTableBody>
                    {
                      employeeList?.data.map((emp, idx) => {
                        return <AppoveTableRow key={idx} employee={new Employee({ ...emp })} handleCheckbox={handleCheckbox} checkedIDs={checkedIDs} onModalApprove={onModalApprove} />
                      })
                    }
                  </CTableBody>
                </CTable>
              </Tab.Pane>
              <Tab.Pane eventKey={STATUS.RESIGN}>
                <CTable responsive="xl" align="middle">
                  <CTableHead>
                    <TableHeaderRender listType={searchData.listType} />
                  </CTableHead>
                  <CTableBody>
                    {
                      employeeList?.data.map((emp, idx) => {
                        return <ReignedTableRow key={idx} employee={new Employee({ ...emp })} handleCheckbox={handleCheckbox} checkedIDs={checkedIDs} />
                      })
                    }
                  </CTableBody>
                </CTable>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          {(() => {
            return !!employeeList?.data.length || <NoDataTable />
          })()}
          {
            !!employeeList?.data.length &&
            <Pagination
              limit={employeeList?.per_page}
              totalItems={employeeList?.total_items}
              current_page={employeeList?.current_page}
              navigate={tranferPageto}
            />
          }
        </div>
        <div style={{ height: 150 }}></div>
      </CContainer>
    </>
  );
};

export default EmployeeScreen;
