/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { CButton,CModal,CModalHeader,CModalTitle,CModalBody,CModalFooter,CFormCheck, CRow, CCol  } from "@coreui/react";
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../app/hooks';
import {uploadFileEmployee,selectDataDownloadFileEmployee} from "../../../../../features/business/businessSlices";
import { useTranslation } from 'react-i18next';
interface ModalUpdateCustomer{
  visible:boolean;
  onCancel:()=>void;
  downloadFile:()=>void;
}

const ModalUploadEmployees = (props: ModalUpdateCustomer) => {
    const {visible,onCancel,downloadFile} = props;
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const dataUploadFile = useAppSelector(selectDataDownloadFileEmployee);
    const [headings,setHeadings] = React.useState(false);
    const [fileUpLoad,setFileUpLoad] = React.useState({});
    const onSelectFile = (e:any)=>{
        setFileUpLoad(e.target.files[0]);
    };
    const updateFile = () => {
        const vendor_id = localStorage.getItem('vendor_id');
        const user_id = localStorage.getItem('user_id');
    
        const formData = new FormData();
        //@ts-ignore
        formData.append('file', fileUpLoad);
        //@ts-ignore
        formData.append('vendor_id', vendor_id);
        //@ts-ignore
        formData.append('user_id', user_id);
        //@ts-ignore
        formData.append('headings',headings?"1":"0");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(uploadFileEmployee(formData));
        //@ts-ignore
    };

 
  
  
    // const downloadFile = async () =>{
    //     const response =  await Business.downloadFile(headings?"1":"0");
    //     const link = document.createElement('a');
    //     link.href = response.data.data.file_path;
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     //@ts-ignore
    //     //    dispatch(downloadFileCustomer(headings?"1":"0"));

    // };
    return(
        <CModal
            className="show d-block position-absolute align-items-center pt-5"
            backdrop={true}
            keyboard={false}
            portal={false}
            visible={visible}
            onClose={onCancel}
        >
          
            <CModalHeader>
                <CModalTitle>Upload Employee</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <span >Choose file (.xls):
                    <input className="ms-2" type="file" id="input" onChange={(e)=>onSelectFile(e)} />
                </span>
                <CFormCheck onChange={()=>{setHeadings(!headings);}} id="flexCheckDefault" label="The first row contains column heading"/>
              <p  style={{color:"red"}}>{dataUploadFile?.file}</p> 

              <a onClick={downloadFile} download  className="my-3" style={{cursor:"pointer",color:"#059ED7",fontWeight: 500}}>Download sameple excel file </a>
                <p  style={{color:"red"}}>{dataUploadFile?.error_message?.length?(dataUploadFile?.error_message[0]):(null)}</p> 
            </CModalBody>
            <CModalFooter>
                <CRow className='w-100'>
                    <CCol>
                        <CButton onClick={onCancel}  className='w-100 border' color="transparent">{t('common.cancel')}</CButton>
                    </CCol>
                    <CCol>
                        <CButton onClick={updateFile} className='w-100' color="primary">{t('common.upload')}</CButton>
                    </CCol>
                </CRow>

            </CModalFooter>
        </CModal>
    );
};

export default ModalUploadEmployees;