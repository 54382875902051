import {
    CBreadcrumb,
    CBreadcrumbItem,
    CButton,
    CCol,
    CContainer,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CRow,
} from "@coreui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useAppSelector } from "app/hooks";
import {
    getDistricts,
    getProvinces,
    getWards,
    SelectDistricts,
    SelectProvinces,
    SelectWards,
} from "features/register/registerSlices";
import { formatYYYYMMDD } from "utils/function";
import { useDispatch } from "react-redux";
import EmployeesService from "services/Employee/EmployeesService";
import Business from 'services/Business/Business';
import CommonService from "services/CommonService";

const initData = () => {
    return {
        joining_date: null,
        main_store: "",
        business_location_base: "",
        role: "",
        create_employee_cash_book: false,
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile_number: "",
        email_address: "",
        city: "",
        district: "",
        ward: "",
        address_line: "",
        DOB: null,
        employee_NID: "",
        id_number: "",
        date_of_issue: null,
        expiry_date: null,
        place_of_issue: "",
        vendor_location_id: "",
        employee_number: ""
    };
};

const AddEmployeeScreen = () => {
    const { t } = useTranslation();
    const [data, setData] = React.useState(initData());
    const listProvinces = useAppSelector(SelectProvinces);
    const listDistricts = useAppSelector(SelectDistricts);
    const listWards = useAppSelector(SelectWards);
    const [locations, setLocations] = React.useState<any>();
    const [erros, setErros] = React.useState<any>();
    const [isExisted, setIsExisted] = React.useState(false);

    const { user } = CommonService.user()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleDataChange = (field: string, value: any) => {
        setData((prev) => ({ ...prev, [field]: value }));
    };

    const getLocation = () => {
        // EmployeesService.getLocationList().then((res) => {
        //     setLocations(res.data.data);
        // });
        const vendor_id = localStorage.getItem('vendor_id')
        vendor_id && Business.listLocation(vendor_id).then((res) => {
            setLocations(res.data.data);
        });
    };

    React.useEffect(() => {
        getLocation();
    }, []);

    const handleChangeAddress = React.useCallback(
        (field: string, value: any) => {
            if (field === "city") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                dispatch(getDistricts(value));
                handleDataChange("city", value);
                handleDataChange("district", "");
                handleDataChange("ward", "");
            } else if (field === "district") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                dispatch(getWards(value));
                handleDataChange("district", value);
            } else {
                handleDataChange("ward", value);
            }
        },
        [data.city, data.district, data.ward, handleDataChange]
    );

    const handleFillData = (mobiphone) => {
        handleDataChange('mobile_number', mobiphone);
        Business.getUserByPhone(mobiphone, user.mobile_phone).then(res => {
            if (res.data.code === 0) {
                const data = res.data.data
                setData((prev) => (
                    {
                        ...prev,
                        first_name: data?.first_name,
                        middle_name: data?.middle_name,
                        last_name: data?.last_name
                    }))
                data?.first_name && data?.last_name && setIsExisted(true)
            }
            else {
                isExisted &&
                    setData((prev) => (
                        {
                            ...prev,
                            first_name: '',
                            middle_name: '',
                            last_name: '',
                        }))
                setIsExisted(false)
            }
        })
    }

    const handleSave = () => {
        const postData = {
            employee_number: data.employee_number,
            user_id: localStorage.getItem("user_id"),
            vendor_id: localStorage.getItem("vendor_id"),
            vendor_location_id: data.vendor_location_id,
            mobile_phone: data.mobile_number,
            first_name: data.first_name,
            last_name: data.last_name,
            middle_name: data.middle_name,
            email: data.email_address,
            date_of_birth: formatYYYYMMDD(data.DOB),
            city_id: data.city,
            district_id: data.district,
            ward_id: data.ward,
            address: data.address_line,
            nid: data.id_number,
            date_of_issue: formatYYYYMMDD(data.date_of_issue),
            expiry_date: formatYYYYMMDD(data.expiry_date),
            place_of_issue: data.place_of_issue,
            join_date: formatYYYYMMDD(data.joining_date),
            role_id: "",
            add_cash_book: 1,
        };

        EmployeesService.add(postData)
            .then((res) => {
                if (!res.data.code) {
                    navigate(`/employee_management/employees`);
                } else {
                    console.log(res.data.messageObject);

                    setErros(res.data.messageObject);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    React.useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getProvinces());
    }, []);

    return (
        <CContainer fluid>
            <CBreadcrumb>
                <CBreadcrumbItem>
                    <Link className="breadcrumb-link text-secondary" to='/employee_management/employees'>{t('employee.employees')}</Link>
                </CBreadcrumbItem>
                <CBreadcrumbItem className="text-primary" active>
                    {t("employee.add_employee")}
                </CBreadcrumbItem>
            </CBreadcrumb>

            <h3>{t("employee.add_employee")}</h3>
            <CRow>
                <CCol className="mb-2" sm={12} md={3}>
                    <h5 className="fs-5">{t("employee.business_information")}</h5>
                </CCol>
                <CCol className="mb-2" sm={12} md={9}>
                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormInput
                                type="text"
                                placeholder="E001"
                                name="employee_number"
                                value={data.employee_number}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            />
                            {erros?.employee_number && (
                                <div className="text-danger">{erros?.employee_number}</div>
                            )}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="joining_date"
                            >
                                {t("employee.joining_date")} *
                            </CFormLabel>
                            <DatePicker
                                dateFormat={"yyyy-MM-dd"}
                                id="joining_date"
                                className="form-control"
                                name="joining_date"
                                selected={data.joining_date}
                                onChange={(date: Date) =>
                                    handleDataChange("joining_date", date)
                                }
                                placeholderText={t("employee.joining_date")}
                            />
                            {erros?.join_date && (
                                <div className="text-danger">{erros.join_date}</div>
                            )}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="business_location_base"
                            >
                                {t("employee.business_location_base")} *
                            </CFormLabel>
                            <CFormSelect
                                id="business_location_base"
                                name="vendor_location_id"
                                aria-describedby={t("employee.business_location_base")}
                                value={data.vendor_location_id}
                                placeholder={t("employee.business_location_base")}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            >
                                <option value="">{t("employee.business_location_base")}</option>
                                {locations?.map((item: any, idx) => (
                                    <option
                                        key={idx}
                                        value={item.id}
                                    >
                                        {item.vendor_location_name}
                                    </option>
                                ))}
                            </CFormSelect>
                            {erros?.vendor_location_id && (
                                <div className="text-danger">{erros.vendor_location_id}</div>
                            )}
                        </CCol>
                    </CRow>
                    {/* <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="select_role"
                            >
                                {t("employee.role")} *
                            </CFormLabel>
                            <CFormSelect
                                type="text"
                                id="select_role"
                                placeholder={t("employee.select_role")}
                                aria-describedby={t("employee.select_role")}
                            ></CFormSelect>
                        </CCol>
                    </CRow>
                    <Link className="text-primary text-decoration-none" to={"/"}>
                        {t("employee.add_new_role")}
                    </Link> */}
                    <br />
                    <CCol className="d-flex py-2">
                        <CFormCheck
                            id="create_employee_cash_book"
                            type="checkbox"
                            name="create_employee_cash_book"
                            onChange={(e) => handleDataChange(e.target.name, e.target.value)}
                        />{" "}
                        &nbsp;&nbsp;
                        <CFormLabel
                            className="mb-0"
                            style={{ fontWeight: 600 }}
                            htmlFor="create_employee_cash_book"
                        >
                            {t("employee.create_employee_cash_book")} *
                        </CFormLabel>
                    </CCol>
                </CCol>
            </CRow>
            <hr />
            <CRow>
                <CCol className="mb-2" sm={12} md={3}>
                    <h5 className="fs-5">
                        {t("employee.employee_personal_information")}
                    </h5>
                </CCol>
                <CCol className="mb-2" sm={12} md={9}>
                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="mobile_number"
                            >
                                {t("employee.mobile_number")} *
                            </CFormLabel>
                            <CFormInput
                                type="number"
                                id="mobile_number"
                                name="mobile_number"
                                aria-describedby={t("employee.mobile_number")}
                                onChange={(e) => handleFillData(e.target.value)}
                            />
                            {erros?.mobile_phone && (
                                <div className="text-danger">{erros.mobile_phone[0]}</div>
                            )}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="first_name"
                            >
                                {t("employee.first_name")} *
                            </CFormLabel>
                            <CFormInput
                                type="text"
                                id="first_name"
                                aria-describedby={t("employee.first_name")}
                                name="first_name"
                                onChange={(e) => handleDataChange(e.target.name, e.target.value)}
                                value={data?.first_name || ''}
                                disabled={isExisted}
                            />
                            {erros?.first_name && (
                                <div className="text-danger">{erros.first_name}</div>
                            )}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="middle_name"
                            >
                                {t("employee.middle_name")}
                            </CFormLabel>
                            <CFormInput
                                type="text"
                                id="middle_name"
                                aria-describedby={t("employee.middle_name")}
                                name="middle_name"
                                onChange={(e) => handleDataChange(e.target.name, e.target.value)}
                                value={data?.middle_name || ''}
                                disabled={isExisted}
                            />
                            {erros?.middle_name && (
                                <div className="text-danger">{erros.middle_name}</div>
                            )}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="last_name"
                            >
                                {t("employee.last_name")} *
                            </CFormLabel>
                            <CFormInput
                                type="text"
                                id="last_name"
                                aria-describedby={t("employee.last_name")}
                                name="last_name"
                                onChange={(e) => handleDataChange(e.target.name, e.target.value)}
                                value={data?.last_name || ''}
                                disabled={isExisted}
                            />
                            {erros?.last_name && (
                                <div className="text-danger">{erros.last_name}</div>
                            )}
                        </CCol>

                    </CRow>

                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="email_address"
                            >
                                {t("employee.email_address")}
                            </CFormLabel>
                            <CFormInput
                                type="text"
                                id="email_address"
                                aria-describedby={t("employee.email_address")}
                                name="email_address"
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            />
                            {erros?.email && <div className="text-danger">{erros.email}</div>}
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormLabel
                                className="mb-1 pt-2"
                                style={{ fontWeight: 600 }}
                                htmlFor="address"
                            >
                                {t("employee.address")}
                            </CFormLabel>
                        </CCol>
                    </CRow>
                    <CRow className="pb-sm-0 pb-md-2">
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormSelect
                                id="city"
                                name="city"
                                defaultValue={""}
                                placeholder={t("employee.city")}
                                aria-describedby={t("employee.city")}
                                onChange={(e) =>
                                    handleChangeAddress(e.target.name, e.target.value)
                                }
                            >
                                <option value="">{t("employee.city")}</option>
                                {listProvinces?.map((item: any, idx) => (
                                    <option key={idx} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </CFormSelect>
                            {erros?.city && <div className="text-danger">{erros.city}</div>}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormSelect
                                id="district"
                                name="district"
                                defaultValue={""}
                                placeholder={t("employee.district")}
                                aria-describedby={t("employee.district")}
                                onChange={(e) =>
                                    handleChangeAddress(e.target.name, e.target.value)
                                }
                            >
                                <option value="">{t("employee.district")}</option>
                                {data.city &&
                                    listDistricts?.map((item: any, idx) => (
                                        <option key={idx} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </CFormSelect>
                            {erros?.district_id && (
                                <div className="text-danger">{erros.district_id}</div>
                            )}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormSelect
                                id="ward"
                                name="ward"
                                defaultValue={""}
                                placeholder={t("employee.ward")}
                                aria-describedby={t("employee.ward")}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            >
                                <option value="">{t("employee.ward")}</option>
                                {data.district &&
                                    listWards?.map((item: any, idx) => (
                                        <option key={idx} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                            </CFormSelect>
                            {erros?.ward_id && <div className="text-danger">{erros.ward_id}</div>}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="mb-2">
                            <CFormInput
                                type="text"
                                id="address_line"
                                name="address_line"
                                value={data.address_line}
                                placeholder={t("employee.address_line")}
                                aria-describedby={t("employee.address_line")}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            />
                            {erros?.address && <div className="text-danger">{erros.address}</div>}
                        </CCol>
                    </CRow>
                    <CCol className="mb-2" sm={12} md={4} xl={4}>
                        <CFormLabel
                            className="mb-1 pt-2"
                            style={{ fontWeight: 600 }}
                            htmlFor="DOB"
                        >
                            {t("employee.DOB")}{" "}
                        </CFormLabel>
                        <DatePicker
                            id="DOB"
                            name="DOB"
                            className="form-control"
                            selected={data?.DOB || null}
                            dateFormat={"yyyy-MM-dd"}
                            placeholderText={t("employee.DOB")}
                            onChange={(date: Date) => {
                                handleDataChange("DOB", date);
                            }}
                        />
                        {erros?.date_of_birth && (
                            <div className="text-danger">{erros.date_of_birth}</div>
                        )}
                    </CCol>
                    <CCol className="mb-2" sm={12} md={4} xl={4}>
                        <CFormLabel
                            className="mb-1 pt-2"
                            style={{ fontWeight: 600 }}
                            htmlFor="employee_NID"
                        >
                            {t("employee.employee_NID")}
                        </CFormLabel>
                    </CCol>
                    <CRow className="pb-sm-0 pb-md-2">
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <CFormInput
                                type="text"
                                id="id_number"
                                value={data.id_number}
                                name="id_number"
                                placeholder={t("employee.id_number")}
                                aria-describedby={t("employee.id_number")}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            />
                            {erros?.nid && (
                                <div className="text-danger">{erros.nid}</div>
                            )}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <DatePicker
                                dateFormat={"yyyy-MM-dd"}
                                id="date_of_issue"
                                name="date_of_issue"
                                className="form-control"
                                selected={data.date_of_issue}
                                placeholderText={t("employee.date_of_issue")}
                                onChange={(date: Date) => {
                                    handleDataChange("date_of_issue", date);
                                }}
                            />
                            {erros?.date_of_issue && (
                                <div className="text-danger">{erros.date_of_issue}</div>
                            )}
                        </CCol>
                        <CCol className="mb-2" sm={12} md={4} xl={4}>
                            <DatePicker
                                dateFormat={"yyyy-MM-dd"}
                                id="expiry_date"
                                name="expiry_date"
                                className="form-control"
                                selected={data.expiry_date}
                                onChange={(date: Date) => {
                                    handleDataChange("expiry_date", date);
                                }}
                                placeholderText={t("employee.expiry_date")}
                            />
                            {erros?.expiry_date && (
                                <div className="text-danger">{erros.expiry_date}</div>
                            )}
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className="mb-2">
                            <CFormInput
                                type="text"
                                id="place_of_issue"
                                name="place_of_issue"
                                value={data.place_of_issue}
                                placeholder={t("employee.place_of_issue")}
                                aria-describedby={t("employee.address_line")}
                                onChange={(e) =>
                                    handleDataChange(e.target.name, e.target.value)
                                }
                            />
                            {erros?.place_of_issue && (
                                <div className="text-danger">{erros.place_of_issue}</div>
                            )}
                        </CCol>
                    </CRow>
                </CCol>
            </CRow>
            <hr className="mb-5" />
            <CButton onClick={handleSave}>{t("common.save")}</CButton>
            <Link to={'/employee_management/employees'} className="btn btn-secondary ms-2" >
                {t("common.cancel")}
            </Link>
            <div style={{ height: 150 }}></div>
        </CContainer>
    );
};

export default AddEmployeeScreen;
