import React from 'react';
import { CFormCheck, CTableDataCell, CTableRow } from "@coreui/react";
import { Link } from "react-router-dom";
import Employee from "../interfaces/EmployeeDto";
import { STATUS } from "../constant";
import CommonService from "services/CommonService";


interface propTypes {
  handleCheckbox: (id: any) => void;
  checkedIDs: Array<any>;
  employee: Employee;
  onModalApprove: any
}

const Status = ({ status_message, status, onModal, employee }: any) => {
  switch (status) {
    case 1:
      return <>
        <span role={'button'} style={{ color: '#FFA800' }} onClick={() => onModal(employee)}>
          {status_message}
        </span>
      </>
    case 2:
      return <span style={{ color: '#04AC9C' }}>{status_message}</span>
    case 3:
      return <span style={{ color: '#FFA800' }}>{status_message}</span>
    case 4:
      return <span style={{ color: '#04AC9C' }}>{status_message}</span>
  }
  return <></>
}

const AppoveTableRow = (props: propTypes) => {
  const { onModalApprove } = props;
  const {
    employee_number,
    mobile_phone,
  } = props.employee.dto;

  return (
    <>
      <CTableRow>
        <CTableDataCell className="p-3">
          <CFormCheck
            type="checkbox"
            checked={props.checkedIDs.includes(employee_number)}
            onChange={() => props.handleCheckbox(employee_number)}
          />
        </CTableDataCell>
        <CTableDataCell className="py-3">{employee_number}</CTableDataCell>
        <CTableDataCell className="py-3">
          <Link to={props.employee.getDetailLink()} state={{ beforeTab: STATUS.APPROVE }}>
            {props.employee.getName()}
          </Link>
          <br />
          {mobile_phone}
        </CTableDataCell>
        <CTableDataCell className="py-3">{props.employee.getRequestDate()}</CTableDataCell>
        <CTableDataCell className="py-3 text-success">
          <Status employee={props.employee.dto} status={props.employee.dto.status} status_message={props.employee.dto.status_message} onModal={onModalApprove}/>
        </CTableDataCell>
      </CTableRow>

    </>
  );
};

export default AppoveTableRow;
