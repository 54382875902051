import { formatYYYYMMDD } from "utils/function"

export interface EmployeeDto {
    id?: any
    user_id?: any
    approved_by_user_id?: any
    cashbook?: boolean
    vendor: any
    manager: string
    getEmployees?: any
    // Address
    address?: any,
    city?: any
    city_id?: any
    district?: any
    district_id?: any
    ward?: any
    ward_id?: any
    resignation_path?: any

    // Date
    date_of_birth?: any
    date_of_issue?: any
    expiry_date?: any
    join_date?: any
    release_request_date?: any
    request_approval_date?: any
    resigned_date?: any 

    // Employee Attributes
    employer_user_id?: any
    employee_number?: any
    first_name?: any
    last_name?: any
    middle_name?: any
    nid?: any
    status: any
    status_message?: any
    vendor_location?: any
    role?: any
    mobile_phone?: any
}

export default class Employee {

    dto: EmployeeDto;
    debug = false;

    constructor(dto: any = {}){
        this.debug && console.log(dto);
        this.dto = { ...dto }
    }

    toDate(key: keyof EmployeeDto) : Date | null {
        return this.dto[key] ? new Date(this.dto[key]) : null
    }

    dateToString(key: keyof EmployeeDto) : string {
        return formatYYYYMMDD(this.dto[key]);
    }

    getName(){
        // last + midd + first
        return `${this.dto.last_name}  ${this.dto.middle_name || ''} ${this.dto.first_name}`
    }

    getDetailLink () {
        return `/employee_management/employees/detail/${this.dto.id}`
    }

    getEditLink () {
        return `/employee_management/employees/edit/${this.dto.id}`
    }

    getRequestDate() {
        return this.dto?.request_approval_date || ''
    }

    getResignedDetailLink () {
        return `/employee_management/employees/resigned-detail/${this.dto.id}`
    }
}