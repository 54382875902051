import IconSale from '../../assets/images/icon-sale.svg';
import IconBuy from '../../assets/images/icon-buy.svg';
import IconEmployee from '../../assets/images/icon-employee.svg';
import IconPromotion from '../../assets/images/icon-promotion.svg';
import IconBook from '../../assets/images/icon-book.svg';
import IconInventory from '../../assets/images/icon-inventory.svg';
import IconSetting from '../../assets/images/icon-setting.svg';
import IconDot from '../../assets/images/Dot.svg';
import IconCustom from '../../assets/images/customer.svg';

export const sidebarData = [
    // {
    //     link: '/sale',
    //     icon: IconSale,
    //     label: 'sidebar.sale'
    // },
    // {
    //     link: '/buy',
    //     icon: IconBuy,
    //     label: 'sidebar.buy'
    // },
    {
        link: '/employee_management',
        icon: IconEmployee,
        label: 'sidebar.employee_management',
        subLinks: [
            {
                link: '/employees',
                icon: IconDot,
                label: 'sidebar.employees',
            },
            // {
            //     link: '/roles',
            //     icon: IconDot,
            //     label: 'sidebar.roles',
            // },
        ]
    },
    // {
    //     link: '/distributors',
    //     icon: IconBuy,
    //     label: 'sidebar.distributors'
    // },
    {
        link: '/customers',
        icon: IconCustom,
        label: 'sidebar.customers'
    },
    
    // {
    //     link: '/promotions',
    //     icon: IconPromotion,
    //     label: 'sidebar.promotions'
    // },
    // {
    //     link: '/inventory',
    //     icon: IconInventory,
    //     label: 'sidebar.inventory'
    // },
    {
        link: '/cashbook',
        icon: IconBook,
        label: 'sidebar.cashbook',
        subLinks:[
            {
                link: '/payment',
                icon: IconDot,
                label: 'payment.payment_consolidation',
            },
            // {
            //     link: '/roles',
            //     icon: IconDot,
            //     label: 'sidebar.roles',
            // },
        ]
    },
    // {
    //     link: '/settings',
    //     icon: IconSetting,
    //     label: 'sidebar.settings'
    // },
]