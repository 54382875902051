export interface ICustomer {
    id?: any;
    user_customer?: UserCustomer;
    vendor_customer?: VendorCustomer;
    user_customer_id?: any;
    vendor_customer_id?: any;
  }
  
  interface UserCustomer {
    first_name?: any;
    last_name?: any;
    middle_name?: any;
    id?: any;
  }
  
  interface VendorCustomer {
    created_by_user_id?: any;
    id?: any;
    vendor_business_type?: any;
    vendor_name?: any;
    vendor_type?: any;
  }
  
  export default class CustomerDetail {
    data: ICustomer = {};
    constructor(data) {
      this.data = { ...data };
    }
    getName() {
      return `${this.data.user_customer.first_name} ${this.data.user_customer.middle_name || ""} ${this.data.user_customer.last_name}`;
    }
    getVendorType() {
        if (!this.data.vendor_customer) {
           return ""
        }
        switch (this.data.vendor_customer.vendor_type) {
          case 1:
            return "Retailers";
          case 2:
            return "Distributors";
          default:
            return "";
        }
      }
      getVendorName() {
        if (!this.data.vendor_customer) {
          return ""
       }
       return this.data.vendor_customer.vendor_name
      }
  }
  