import http from "../utils/http";

class Authentication {

  registerStep1(data: any) {
    return http.post(`/register/step-1`, 
      data,
    );
  }
  reSendUserOTP(data:any){
    return http.post(`/register/resend-otp`,data );
  }
  sendCodeOTP(data:any){
    return http.post(`/register/step-2`,data );
  }
  provinces(){
    return http.get(`/get-city-list`);
  }
  district(city_id:number){
    return http.get(`/get-district-list?city_id=${city_id}`);
  }
  wards(district_id:number){
    return http.get(`/get-ward-list?district_id=${district_id}`);
  }
  login(data:any){
    return http.post(`/portal/login`, 
      data,
    );
  }
  forgotPasswordStep1(data:any){  
    return http.post(`/forgot-password/step-1`,data);
  }
  forgotPasswordStep2(data:any){
    return http.post(`/forgot-password/step-2`,data);
  }
  profileList(user_id:any){
    return http.get(`/portal/profile-list?user_id=${user_id}`);
  }
  getProfile(vendor_id:any){
    return http.get(`/portal/profile?vendor_id=${vendor_id}`);
  }

}

export default new Authentication();


