import React from "react";
import { CTableRow, CTableDataCell} from "@coreui/react";
import PaymentObj from "./types/IPayment";
import ModalDetailOrder from "./modals/ModalDetailOrder";
interface props {
    payment: PaymentObj
}
const TableRow = (props: props) => {

    const { payment } = props;
    const [visible, setVisible] = React.useState(false);
    const showDetail = () => {
        console.log(payment.payment.order_list);
        setVisible(true)
    }

    return (
        <>
            <ModalDetailOrder visible={visible} setVisible={setVisible} order_list={payment.payment.order_list} />
            <CTableRow>
                <CTableDataCell className="p-3"></CTableDataCell>
                <CTableDataCell>{payment.getCustomerName()}</CTableDataCell>
                <CTableDataCell>{payment.getAssignedEmployeeName()}</CTableDataCell>
                <CTableDataCell>{payment.getPaymenRequest()}</CTableDataCell>
                <CTableDataCell>{payment.getPaymentReceipt()}</CTableDataCell>
                <CTableDataCell>{payment.getAmountRequest()}</CTableDataCell>
                <CTableDataCell>{payment.getAmountReceived()}</CTableDataCell>
                <CTableDataCell>{payment.getCreditLine()}</CTableDataCell>
                <CTableDataCell>{payment.getBankPayment()}</CTableDataCell>
                <CTableDataCell>{payment.getCashPayment()}</CTableDataCell>
                <CTableDataCell onClick={showDetail}><button className="btn btn-outline-primary">Show detail</button></CTableDataCell>
            </CTableRow>
        </>
    );
};

export default TableRow;
