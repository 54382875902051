import React from 'react';
import { CSidebarBrand, CSidebarNav, CNavLink, CFormSwitch } from '@coreui/react';
import { sidebarData } from './SidebarData';
import SidebarLink from './SidebarLink';
import logo from 'assets/images/logo.png';
import { useAppSelector } from 'app/hooks';
import { selectSidebar } from 'features/theme/themeSlide';
import { collapseStyle } from 'utils/function';
import logoutIcon from 'assets/images/logout.svg';
import avatar from 'assets/images/avatar-default.svg';
import i18n from 'utils/i18next';


function Sidebar() {

  const { isCollapse } = useAppSelector(selectSidebar);
  const [user] = React.useState(JSON.parse(localStorage.getItem('user') || ''))
  const lang = localStorage.getItem('lang');
  const [showLang, setShowLang] = React.useState(lang === "true" ? true : lang === "false" ? false : true);
  function deleteAllCookies() {
    (window as any).fcWidget.user.clear();
    localStorage.clear();
    sessionStorage.clear();
  }

  React.useEffect(() => {
    if (showLang === true) {
      i18n.changeLanguage('vi');
    } else {
      i18n.changeLanguage('en')
    }
    localStorage.setItem('lang', showLang.toString())
  }, [showLang])
  return (
    <div className={`mysidebar overflow-hidden ${!!isCollapse && 'collapsed'}`}>
      <CSidebarBrand className={`justify-content-start `}>
        <CNavLink
          className='d-flex align-items-center mx-2'
          href='/'
          style={{ height: 84 }}
        >
          <img
            src={logo}
            alt=''
            className='rounded-pill'
            style={style.logo}
          />
        </CNavLink>
        <div
          className='w-100 text-white text-nowrap'
          style={collapseStyle(isCollapse)}
        >
          MyBiz Business Portal
          <br />
          <span className='text-muted'>Distributor</span>
        </div>
      </CSidebarBrand>
      <CSidebarNav>
        {sidebarData.map((link) => (
          <SidebarLink
            key={link.link}
            subLinks={link.subLinks}
            icon={link.icon}
            label={link.label}
            link={link.link}
          />
        ))}
      </CSidebarNav>
      <hr className='' />
      <CSidebarBrand className={`mt-auto justify-content-start px-2 border-top border-1 border-secondary`}>
        <CNavLink
          className='d-flex align-items-center mx-2'
          href='/'
          style={{ height: 84 }}
        >
          <img
            src={avatar}
            alt=''
            className='rounded-pill'
            style={style.logo}
          />
        </CNavLink>
        <div
          className='w-100 text-white text-nowrap'
          style={collapseStyle(isCollapse)}
        >
          {`${user.last_name || ''} ${user.middle_name || ''} ${user.first_name || ''}`.trim() || localStorage?.getItem('onwnerName')}
          <br />
          <span className='text-muted text-limited'>{user.email}</span>
          <div>
            <CFormSwitch label={showLang ? "VIE" : "EN"} id="formSwitchCheckChecked" defaultChecked={showLang} onChange={() => setShowLang(!showLang)} />
          </div>
        </div>
        <span className='ms-auto p-2' role={'button'}
          onClick={() => {
            (window as any).fcWidget.destroy();
            deleteAllCookies();
            window.location.reload();
          }}>
          <img src={logoutIcon} alt="" width={14} height={14} />
        </span>

      </CSidebarBrand>
    </div>
  );
}

export default Sidebar;

const style = {
  logo: {
    width: 32,
    height: 32,
    margin: '0 15px',
  },
};
