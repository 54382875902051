import React from 'react';
import { CButton, CForm, CFormInput } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import Authentication from 'services/Authentication';
import Footer from 'components/Footer/footer';


const ForgotPassword = () =>{
    const [mobilePhone,setMobilePhone] = React.useState('');
    const [validation, setValidation] = React.useState<
    | {
        mobile_phone: string[] | undefined;
        email: string[] | undefined;
      }
    | undefined
  >();

    const navigate = useNavigate();

    const onRecover = async(e: any) => {
        e.preventDefault();
        try{
            const dataResendOTP = {mobile_phone:mobilePhone,type:"forgot-password"};
            const response =  await Authentication.reSendUserOTP(dataResendOTP);
            if (response.data.code === 2) {
                setValidation(response.data.messageObject);
            }else{
                navigate(`/sign-in/verification?mobile_phone=${mobilePhone}&type=changepass`);
            }
        }
        catch (error){
            console.log(error);
        }
    };
    return (
        <div style={{    height: "100%",
        display: "flex",
        flexDirection: "column"}}>
            <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                <img src={require("../../assets/images/logo.png")} />
                <h6 className="my-1" style={{ marginLeft: 5 }}>
           MyBiz Business Portal
                </h6>
            </div>
            <div className="my-5">
                <h2 className="text-center">Forgot Password</h2>
                <CForm onSubmit={onRecover} className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>

                    <CFormInput onChange={(e:any)=>setMobilePhone(e.target.value)} type="phone" placeholder="Enter your phone" />
                    <span  style={{color:"red"}}>{validation?.mobile_phone?.length?(validation.mobile_phone[0]):null}</span>
                    <div className="d-grid gap-2 mt-3">
                        <CButton color="primary" type="submit">
                        Recover
                        </CButton>
                    </div>
                
                </CForm>
            </div>
            <Footer />

        </div>);
};
export  default ForgotPassword;