import React from 'react';
import {
    CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter,
    CFormLabel, CRow, CCol, CTable, CTableHead, CTableRow, CTableHeaderCell,
    CTableBody, CTableDataCell
} from "@coreui/react";
import IconUpdate from 'assets/images/icon-update-customer.png';
import Business from "services/Business/Business";
import { useTranslation } from 'react-i18next';

interface ModalUpdateCompelete {
    visible: boolean;
    data: any;
    onCancel: () => void;
}
const ModalUpdateCompelete = (props: ModalUpdateCompelete) => {
    const { visible, data, onCancel } = props;
    const { t } = useTranslation();
    const downloadErrFile = async () => {
        const dataUpload = {
            "data": data?.error_data,
        };
        const response = await Business.downloadErrFile(dataUpload);
        console.log("response", response);
        const link = document.createElement('a');
        link.href = response.data.data.file_path;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        if (response?.data?.code == 0) {
            onCancel();
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        // dispatch(downloadErrFileCustomer(dataUpload));
    };
    return (
        <CModal
            size="xl"
            className="show d-block position-absolute align-items-center pt-5"
            backdrop={true}
            keyboard={false}
            portal={false}
            visible={visible}
        >
            <CModalHeader>
                <CModalTitle>{t('customer.upload_customers_completed')}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="text-center">
                    <img src={IconUpdate} />
                </div>
                <div className="text-center">
                    <CFormLabel>
                        <span>{data?.total_push} {t('customer.customers')} </span>{t('customer.are_uploaded')} <span className="text-danger">{data?.total_error}  {t('customer.record_errors')} </span> {t('customer.download_the_error')}
                    </CFormLabel>
                </div>
                <div style={{ overflowY: "scroll", height: 150 }}>
                    <CTable>
                        <CTableHead>
                            <CTableRow>
                                <CTableHeaderCell scope="col"></CTableHeaderCell>
                                <CTableHeaderCell scope="col">Owner First Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Middle Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Last Name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Type</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Mobile</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Business name</CTableHeaderCell>
                                <CTableHeaderCell scope="col">Business Type Detail</CTableHeaderCell>


                            </CTableRow>
                        </CTableHead>

                        <CTableBody >

                            {data?.error_data?.map((item: any, key: any) => (
                                <CTableRow key={key}>
                                    <CTableHeaderCell scope="row">{key + 1}</CTableHeaderCell>
                                    <CTableDataCell>{item.first_name}</CTableDataCell>
                                    <CTableDataCell>{item.middle_name}</CTableDataCell>
                                    <CTableDataCell>{item.last_name}</CTableDataCell>
                                    <CTableDataCell>{item.customer_type}</CTableDataCell>
                                    <CTableDataCell>{item.mobile_phone}</CTableDataCell>
                                    <CTableDataCell>{item.vendor_name}</CTableDataCell>
                                    <CTableDataCell>{item.business_type_name}</CTableDataCell>
                                </CTableRow>
                            ))}

                        </CTableBody>

                    </CTable>
                </div>
            </CModalBody>
            <CModalFooter>
                {data?.total_error > 0 ? (<CRow className='w-100'>
                    <CCol>
                        <CButton onClick={onCancel} className='w-100 border' color="transparent">{t('common.cancel')}</CButton>
                    </CCol>
                    <CCol>
                        <a download onClick={downloadErrFile} href=""> <CButton className='w-100' color="primary">{t('common.download_file')}</CButton></a>
                    </CCol>
                </CRow>) : (null)
                }

            </CModalFooter>
        </CModal>
    );
};
export default ModalUpdateCompelete;