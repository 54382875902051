import { CButton } from '@coreui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import ModalProfile from './ModalProfile';
import {getProfileList,selectListProfile} from '../../../features/login/loginSlices';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../app/hooks';
import Footer from '../../../components/Footer/footer';

const ProfileSlectScreen = () => {
    const token  = localStorage.getItem("token");
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const listProfile  = useAppSelector(selectListProfile);
    React.useEffect(() => {
        if(!token&&token!==undefined) {
            navigate('/sign-in');
        }
    },[]);
    React.useEffect(() => {
        const user_id = localStorage.getItem('user_id');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getProfileList(user_id));
    },[]);
    const onLogout = () => {
        localStorage.removeItem("token");
        navigate(0);
    };
    return(
        <div style={{    height: "100%",
            display: "flex",
            flexDirection: "column"}}>
            <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                <img src={require('../../../assets/images/logo.png')} />
                <h6 className="my-1" style={{ marginLeft: 5 }}>MyBiz Business Portal</h6>
            </div>
            <div>
                <h2 className="text-center">Select Profile</h2>
                <div className="mx-auto " style={{padding:10, maxWidth: 400}}>
                    {listProfile?.map((item:any)=>(
                        <ModalProfile key={item.vendor_id} check={true} 
                            vendor_id={item?.vendor?.id}
                            vendor_name={item?.vendor?.vendor_name}
                            vendor_location_id={item?.vendor?.default_vendor_location.id} 
                            vendor_location_name={item?.vendor?.default_vendor_location.vendor_location_name} 
                            city={item?.vendor?.default_vendor_location?.city?.name}
                            district={item?.vendor?.default_vendor_location?.district?.name}
                            ward={item?.vendor?.default_vendor_location?.ward?.name}
                            address={item?.vendor?.default_vendor_location?.address}
                        />

                    ))}
                    <div className="d-grid gap-2 mt-3">
                        <CButton   onClick={() =>  navigate("/register/register-business-profile")} color="primary" type="submit">
                            {t('customer.add_profile')}
                     
                        </CButton>
                    </div>
                    <p
                      onClick={onLogout}
                        className="text-center"
                        style={{
                            marginTop: 24,
                            color: "#059ED7",
                            cursor: "pointer",
                            fontWeight: 500,
                        }}
                    >
                        {t('registration.sign_out')}
                    </p>
                </div>
        
            </div>
            <Footer />
        </div>
    );
};

export default ProfileSlectScreen;