import React, { useState } from 'react';
import { CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CFormCheck, CRow, CCol, CFormLabel } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { DownloadFile } from 'utils/function';
import PaymentService from 'services/PaymentService';


interface DownloadReportModal {
    visible: boolean;
    setVisible: any
}

interface Payload {
    vendor_id?: string
    start_date?: string
    end_date?: string
    export_type?: string
}

const DownloadReportModal = (prop: DownloadReportModal) => {
    const [payload, setPayload] = useState<Payload>({export_type: 'pdf', start_date: moment().format('YYYY-MM-DD'), end_date: moment().format('YYYY-MM-DD')});
    const { visible, setVisible } = prop;
    const { t } = useTranslation();

    const handleChange = (name, value) => {
        setPayload(pre => ({...pre, [name]: value}));
    }

    const downloadExport = () => {
        const vendor_id = localStorage.getItem('vendor_id')
        vendor_id && PaymentService.downloadExport(vendor_id, payload.start_date, payload.end_date, payload.export_type).then((res) => {
            if (res.data.code === 0) {
                DownloadFile(res.data.data.file_path);
                setVisible(false);
            }
        }).catch((err) => console.log(err))
    }

    return (
        <CModal
            className='show d-block position-absolute align-items-center pt-5'
            backdrop={true}
            keyboard={false}
            portal={false}
            visible={visible}
            onClose={()=> setVisible(false)}
        >
            <CModalHeader>
                <CModalTitle>{t('payment.download_report')}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CFormLabel>{t('payment.file_format')}</CFormLabel>
                <CRow>
                    <CCol sm={12} md={4} xl={4}>
                        <CFormCheck type='radio' name='export_type' value={'pdf'} id='pdf-radio' checked={payload?.export_type === 'pdf'} onChange={(e)=> handleChange(e.target.name, e.target.value)} />
                        <CFormLabel className='ms-2' htmlFor='pdf-radio'>PDF</CFormLabel>
                    </CCol>
                    <CCol sm={12} md={4} xl={4}>
                        <CFormCheck type='radio' name='export_type' value={'excel'} id='xsl-radio' checked={payload?.export_type === 'excel'} onChange={(e)=> handleChange(e.target.name, e.target.value)} />
                        <CFormLabel className='ms-2' htmlFor='xsl-radio' >XLS</CFormLabel>
                    </CCol>
                </CRow>

                {/* <p  style={{color:'red'}}>{dataUploadFile?.file}</p> */}
                <div>
                    <CFormLabel>{t('payment.date_range')}</CFormLabel>
                    <div className='d-flex'>
                        <DatePicker
                            dateFormat={'yyyy-MM-dd'}
                            id='start_date'
                            className='form-control me-2'
                            name='start_date'
                            selected={moment(payload?.start_date).toDate()}
                            onChange={(e) => handleChange('start_date', moment(e).format('YYYY-MM-DD'))}
                        
                        />
                        <DatePicker
                            dateFormat={'yyyy-MM-dd'}
                            id='joining_date'
                            className='form-control ms-2'
                            name='joining_date'
                            selected={moment(payload?.end_date).toDate()}
                            onChange={(e) => handleChange('end_date', moment(e).format('YYYY-MM-DD'))}
                        />
                    </div>
                </div>

            </CModalBody>
            <CModalFooter>
                <CRow className='w-100'>
                    <CCol>
                        <CButton className='w-100 border' onClick={()=> setVisible(false)} color='transparent'>{t('common.cancel')}</CButton>
                    </CCol>
                    <CCol>
                        <CButton className='w-100' color='primary' onClick={downloadExport}>{t('buttonText.download')}</CButton>
                    </CCol>
                </CRow>

            </CModalFooter>
        </CModal>
    );
}
export default DownloadReportModal;