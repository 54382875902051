import React from 'react'
import { CModal, CModalBody, CModalHeader, CModalTitle, CTable, CTableDataCell } from '@coreui/react'

const ModalDetailOrder = ({ order_list, visible, setVisible }: any) => {

    return (
        <CModal
            visible={visible} onClose={() => setVisible(false)}
        >
            <CModalHeader>
                <CModalTitle>Order Detail</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CTable>
                    <thead>
                        <tr>
                            <td>Id</td>
                            <td>Order number</td>
                            <td>Total</td>
                        </tr>
                    </thead>
                    <tbody>
                        {order_list.map(el => {
                            return (
                                <tr key={el.id}>
                                    <CTableDataCell>{el.id}</CTableDataCell>
                                    <CTableDataCell>{el.order_number}</CTableDataCell>
                                    <CTableDataCell>{el.total ? `${el.total} VND` : ''}</CTableDataCell>
                                </tr>
                            )
                        })}
                    </tbody>
                </CTable>
            </CModalBody>
        </CModal>
    )
}

export default ModalDetailOrder