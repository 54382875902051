import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import Authentication from "../../services/Authentication";
const sliceName = "login";
const sliceState = (state) => state.login;
const initialState = {
    dataLogin: [],
    businessProfile: [],
    changePassword: [],
    otpPassword1: [],
    listProfile: []
};


export const login = createAsyncThunk(
    sliceName + "/login",
    // eslint-disable-next-line consistent-return
    async (data) => {
        try {
            const response = await Authentication.login(data);
            if (response.data.code === 2) {
                return response.data.messageObject;
            }
            return response.data;

        } catch (err) {
            console.log("err", err);
        }
    }
);


export const onForgotPasswordStep2 = createAsyncThunk(
    sliceName + "/onForgotPasswordStep2",
    // eslint-disable-next-line consistent-return
    async (data) => {
        try {
            const response = await Authentication.forgotPasswordStep2(data);
            if (response.data.code === 2) {
                return response.data.messageObject;
            }
            return response.data;

        } catch (err) {
            console.log("err", err);
        }
    }
);
export const onForgotPasswordStep1 = createAsyncThunk(
    sliceName + "/onForgotPasswordStep1",
    // eslint-disable-next-line consistent-return
    async (data) => {
        try {
            const response = await Authentication.forgotPasswordStep1(data);
            if (response.data.code === 2) {
                return response.data.messageObject;
            }
            return response.data;

        } catch (err) {
            console.log("err", err);
        }
    }
);
export const getProfileList = createAsyncThunk(
    sliceName + "/getProfileList",
    // eslint-disable-next-line consistent-return
    async (user_id) => {
        try {
            const response = await Authentication.profileList(user_id);
            return response.data;

        } catch (err) {
            console.log("err", err);
        }
    }
);
export const getProfile = createAsyncThunk(
    sliceName + "/getProfile",
    // eslint-disable-next-line consistent-return
    async (vendor_id) => {
        try {
            const response = await Authentication.getProfile(vendor_id);
            return response.data;

        } catch (err) {
            console.log("err", err);
        }
    }
);

//CreateSlice
export const loginSlice = createSlice({
    name: sliceName,
    initialState,
    //ExtraReducer
    extraReducers: (builder) => {
      
        builder.addCase(
            login.fulfilled,
            (state, action) => {
                console.log(action.payload);
                action.payload.data ? (state.dataLogin = action.payload.data) : (state.dataLogin = action.payload);
                
            }
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(login.rejected, (state) => {
            console.log("💀💀Submit result to server fail!");
        });
        builder.addCase(onForgotPasswordStep1.pending, (state) => {
            // state.isLoadingResult = true;
        });
        builder.addCase(
            onForgotPasswordStep1.fulfilled,
            (state, action) => {
                action.payload.data ? (state.otpPassword1 = action.payload.data) : (state.otpPassword1 = action.payload);
            }
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(onForgotPasswordStep1.rejected, () => {
            console.log("💀💀Submit result to server fail!");
        });
        builder.addCase(onForgotPasswordStep2.pending, () => {
            // state.isLoadingResult = true;
        });
        builder.addCase(
            onForgotPasswordStep2.fulfilled,
            (state, action) => {
                action.payload.data ? (state.changePassword = action.payload.data) : (state.changePassword = action.payload);
            }
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(onForgotPasswordStep2.rejected, () => {
            console.log("💀💀Submit result to server fail!");
        });
        builder.addCase(getProfileList.pending, () => {
            // state.isLoadingResult = true;
        });
        builder.addCase(
            getProfileList.fulfilled,
            (state, action) => {
                state.listProfile = action.payload.data;
            }
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        builder.addCase(getProfileList.rejected, () => {
            console.log("💀💀Submit result to server fail!");
        });
    },
});

// //action
// export const {

//   } = appSlice.actions;

//slices
export const selectDataLogin = (state) => sliceState(state).dataLogin;
export const selectBusinessProfile = (state) => sliceState(state).businessProfile;
export const selectForgotPassword1 = (state) => sliceState(state).otpPassword1;
export const selectChangePassword = (state) => sliceState(state).changePassword;
export const selectListProfile = (state) => sliceState(state).listProfile;

export default loginSlice.reducer;