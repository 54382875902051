import React from 'react';
import { CContainer, CRow, CCol } from "@coreui/react";
import IconHome from '../../../assets/images/icon-home.png';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../../../features/login/loginSlices';
import { useDispatch } from 'react-redux';

interface ModalProfile {
    check?: boolean,
    vendor_id: any,
    vendor_name: string,
    vendor_location_name?: string;
    vendor_location_id: string;
    city?: string,
    district?: string,
    ward: string,
    address: string,
}
const ModalProfile = (props: ModalProfile) => {
    const { vendor_id, vendor_location_id, vendor_name, vendor_location_name, city, district, ward, address } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onSlectProfile = () => {
        vendor_id && localStorage.setItem('vendor_id', vendor_id);
        vendor_location_name && localStorage.setItem('vendor_location_id', vendor_location_id);
        vendor_location_name && localStorage.setItem('vendor_location_name', vendor_location_name);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(getProfile(vendor_id));
        // navigate("/", { replace: true });
        window.location.href = "/"
    };
    return (
        <CContainer onClick={onSlectProfile} className="mx-auto border my-3" style={{ cursor: "pointer", padding: 10, maxWidth: 400, borderRadius: 8, boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)" }} >
            <CRow>
                <CCol className="d-flex align-items-center" sm={12} md={3} xl={2}>
                    <img src={IconHome} style={{ width: 40, height: 40 }} alt="" />
                </CCol>
                <CCol sm={12} md={6} xl={8}>
                    <h6>{vendor_name}</h6>
                    <p>Location name: {vendor_location_name}</p>
                    <p>{vendor_location_name ? (address + ", " + ward + " " + district + " " + city) : ""}</p>
                </CCol>
            </CRow>
        </CContainer>
    );
};

export default ModalProfile;