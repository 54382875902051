import React, { useEffect } from "react";
import {
    CRow,
    CCol,
    CContainer,
    CButton,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
} from "@coreui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchBar from "./searchBarPayment";
import TableRow from "./TableRow";
import NoDataTable from "components/NoDataTable";
import Pagination from "components/Pagination/Pagination";
import DownloadReportModal from "./DownloadReportModal";
import PaymentService from "services/PaymentService";
import PaymentObj from "./types/IPayment";

const employeeCol = {
    number: {
        label: "payment.number",
        isShow: true,
    },
    name: {
        label: "payment.customer_name",
        isShow: true,
    },
    employee: {
        label: "payment.assigned_employee",
        isShow: true,
    },
    payment_request: {
        label: "payment.payment_request",
        isShow: true,
    },
    payment_receipt: {
        label: "payment.payment_receipt",
        isShow: true,
    },
    amount_request: {
        label: "payment.amount_request",
        isShow: true,
    },
    amount_receive: {
        label: "payment.amount_receive",
        isShow: true,
    },
    credit_line: {
        label: "payment.credit_line",
        isShow: true,
    },
    bank_payment: {
        label: "payment.bank_payment",
        isShow: true,
    },
    cash_payment: {
        label: "payment.cash_payment",
        isShow: true,
    }
};

const PaymentConsolidationScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [searchData, setSearchData] = React.useState({ keyword: '' });
    const [col] = React.useState(employeeCol);
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [data, setData] = React.useState([])
    const [payments, setPayments] = React.useState<any>();
    const tranferPageto = (page: string, limit: string) => {
        navigate({
            pathname: location.pathname,
            search: `?keyword=${searchParams.get("keyword") || ""}&page=${page}&limit=${limit}`
        });
    };

    useEffect(() => {
        const vendor_id = localStorage.getItem('vendor_id')
        vendor_id && PaymentService.getListPayment(searchData.keyword, vendor_id, 1).then((res) => {
            setData(res.data.data.data);
            setPayments(res.data.data);
        }).catch((err) => console.log(err))
    }, [searchData])

    return (
        <CContainer fluid className="mt-3 p-0" style={{ padding: "0 13px" }}>
            <DownloadReportModal visible={isShowModal} setVisible={setIsShowModal} />
            <CRow className="mb-4">
                <CCol className="mb-4">
                    <h2>{t('payment.payment_consolidation')}</h2>
                </CCol>
                <CCol className="d-flex">
                    <div className="ms-auto">
                        <CButton onClick={() => setIsShowModal(true)} className="ms-2" variant="outline" color="secondary">
                            {t('payment.download_report')}
                        </CButton>
                    </div>
                </CCol>
            </CRow>
            <div style={{
                background: "#F3F5F6",
                borderRadius: '8px',
                padding: 20,
                height: 110,
                marginBottom: 24
            }}>
                <SearchBar searchData={searchData} setSearchData={setSearchData} />
            </div>
            <div>
                <div className="table-wrapper">
                    <CTable responsive="xl" align="middle">
                        <CTableHead>
                            <CTableRow className="py-4">
                                <CTableHeaderCell scope="col"></CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.name.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.employee.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.payment_request.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.payment_receipt.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.amount_request.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.amount_receive.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.credit_line.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.bank_payment.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.cash_payment.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                    {t(col.number.label)}
                                </CTableHeaderCell>
                                <CTableHeaderCell scope="col">
                                </CTableHeaderCell>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {!!data.length && data.map((dt: any) => (
                                <TableRow key={dt.id} payment={new PaymentObj(dt)} />
                            ))}
                        </CTableBody>
                    </CTable>
                    {!data?.length && <NoDataTable />}
                    {!!data?.length && <Pagination
                        limit={payments?.per_page}
                        totalItems={payments?.total}
                        current_page={payments?.current_page}
                        navigate={tranferPageto}
                    />}
                </div>
            </div>


        </CContainer>
    );
}

export default PaymentConsolidationScreen;