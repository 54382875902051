import React from 'react';
import { CButton, CForm, CFormText } from '@coreui/react';
import TextInput from 'components/Input/TextInput';
import IconEgePass from 'assets/images/icon-ege.png';
import IconLineal from 'assets/images/icon-lineal.png';
import Footer from 'components/Footer/footer';
import {onForgotPasswordStep2,selectChangePassword} from 'features/login/loginSlices';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useAppSelector } from 'app/hooks';
import {  useSearchParams } from "react-router-dom";

const ChangePassScreen = () => {
    const dispatch = useDispatch();
    const [isShowPass, setIsShowPass] = React.useState(true);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = React.useState(true);
    const [params] = useSearchParams();
    const mobile_phone = params.get("mobile_phone");
    const otp_code = JSON.parse(localStorage.getItem('otp_code')||'');

    const navigate = useNavigate();
    const dataChangePassWord = useAppSelector(selectChangePassword);
    const acctionRightPassWord = React.useCallback(() => {
        setIsShowPass(!isShowPass);
    }, [isShowPass]);

    const acctionRightConfirmPassWord = React.useCallback(() => {
        setIsShowConfirmPassword(!isShowConfirmPassword);
    }, [isShowConfirmPassword]);

    const ForgotEnum = {
        password: 'password',
        confirmPassword: 'confirmPassword',
    };
    const [data, setData] = React.useState({
        password: '',
        confirmPassword: '',
    });
React.useEffect(() => {
    if(dataChangePassWord.code === 0){

    navigate('/sign-in');
}
},[dataChangePassWord]);

    const onSaveNewPassword = (e:any) => {
        e.preventDefault();
        const dataForgotpassword = {
            mobile_phone:mobile_phone,
            otp_code:otp_code,
            password:data.password,
            password_confirm:data.confirmPassword,
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        dispatch(onForgotPasswordStep2(dataForgotpassword));
        // navigate("/sign-in");
    };

    const onChangeByField = React.useCallback(
        (field: string, value: any) => {
            setData((prevState) => {
                return {
                    ...prevState,
                    [field]: value,
                };
            });
        },
        [setData]
    );
    return (
        <div style={{    height: "100%",
        display: "flex",
        flexDirection: "column"}}>
            <div className="d-flex pt-5" style={{ marginLeft: 44 }}>
                <img src={require('../../assets/images/logo.png')} />
                <h6 className="my-1" style={{ marginLeft: 5 }}>MyBiz Business Portal</h6>
            </div>
            <div>
                {/* <h2>{t('register.user_registration')}</h2> */}
                <h2 className="text-center">Change Password</h2>
                <CForm onSubmit={onSaveNewPassword} className="mx-auto" style={{ maxWidth: 360, marginTop: 32 }}>
                    <CFormText  style={{color: '#3A4348',fontWeight:500}}>New password</CFormText>
                    <TextInput name={ForgotEnum.password} actionChangeText={onChangeByField} type={isShowPass ? ("password") : ("text")} placeholder="Enter your password" acctionRight={acctionRightPassWord}iconRight={isShowPass ? (IconEgePass) : (IconLineal)} />
                    <span  style={{color:"red"}}>{dataChangePassWord?.password}</span>
                    
                    <CFormText style={{color: '#3A4348',fontWeight:500}}>Confirm  password</CFormText>
                    <TextInput name={ForgotEnum.confirmPassword} actionChangeText={onChangeByField} type={isShowConfirmPassword ? ("password") : ("text")} placeholder="Enter your password" acctionRight={acctionRightConfirmPassWord}  iconRight={isShowConfirmPassword ? (IconEgePass) : (IconLineal)} />
                    <span  style={{color:"red"}}>{dataChangePassWord?.password_confirm}</span>

                    <div className="d-grid gap-2 mt-3">
                        <CButton color="primary" type="submit">
                            Save new password
                        </CButton>
                    </div>
                </CForm>

            </div>
            <Footer />
        </div>
    );
};

export default ChangePassScreen;